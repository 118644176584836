import React from 'react';
import styled from "styled-components";
import lock from '../../assets/op/lock.png'
import { useWindowSize } from 'react-use';

const Content = styled.div`
    display:flex ;
    justify-content:space-between ;
    padding:0 50px ;

    font-size:16px ;
    font-family:Lato-Regular ;
    color:#e9e9e9;
    white-space:pre-wrap ;
    h3{
        white-space:pre-line ;
        line-height:20px ;
    }

    &>.left{
        display:flex ;
        align-items:center ;

        img{
            width:35px ;

            @media screen and (max-width: 750px){
                width:25px ;
            }
        }
    }

    @media screen and (max-width: 750px){
        font-size:12px ;
        flex-direction:column ;
        justify-content:center ;
        align-items:center ;
        row-gap:20px;
    }

    @media screen and (max-height: 680px){
        row-gap:15px;
    }
 `
export function Footer() {
    const { width } = useWindowSize();
    return (
        <Content>
            {
                width > 750 ? <>
                    <div className='left'>
                        <h3>Powered by</h3>
                        <img src={lock} alt="" />
                        <h3>Layer Zero</h3>
                    </div>
                    <div className='right'>
                        <h3>{`w / 100+ partners`}</h3>
                    </div>
                </> : <>
                    <div className='right'>
                        <h3>{`w / 100+ partners`}</h3>
                    </div>
                    <div className='left'>
                        <h3>Powered by</h3>
                        <img src={lock} alt="" />
                        <h3>Layer Zero</h3>
                    </div>
                </>
            }

        </Content>
    )
}