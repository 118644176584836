import styled from "styled-components";
// import logo from "./assets/logo_slices/logo@2x.png";
import backgroundImg from "./assets/core/themeLogo.png";
// import bottomImg from '../src/assets/logos.png'

import opLogo from './assets/core/logo.png';
// import {LogoCard} from './comoments/logoCard/index';
import {LogoCard} from './comoments/opLogoCard/index';
import {Footer} from './comoments/footer/index';


const Container = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #161616;

`;

const HeaderWrap = styled.header`
  position:absolute;
  top:23px;
  left:50%;
  transform:translateX(-50%);
  width: 90%;
  height: 53px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoWrap = styled.div`
  width: 320px;
  height: 53px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap:nowrap;
  @media screen and (max-width: 720px){
    width: 160px;
  }
`;
const Logo = styled.img`
  width:335px ;
  height:auto;
  @media screen and (max-width: 1280px) {
    width: 225px;
  }
  @media screen and (max-width: 750px) {
    width: 120px;
  }
`;

const AppButton = styled.button`
  width: 171px;
  height: 40px;
  align-items: center;
  background: #000000;
  border-radius: 30px;
  background-color:#444444;
  border: none;
  cursor: pointer;
  @media screen and (max-width: 720px){
    width: 130px;
  }
  &:hover {
    background: #fc983e;
  }
`;

  const BgImg = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  top: -90px;
  height: 76vh;
  margin: auto;
  @media screen and (max-width: 750px){
    height:66vh;
    left:50%;
    transform:translateX(-50%);
  }
  @media screen and (max-width: 600px){
    height:52vh;
    left:50%;
    transform:translateX(-50%);
  }
`;

const AppText = styled.a.attrs({
  href: "https://app.corens.domains/",
})`
  display:inline-block;
  width:100%;
  height:100%;
  color:#ffffff;
  font-size:18px;
  line-height:40px;
  font-family: 'fontspring-extralight';
  font-weight:normal;
  @media screen and (max-width: 720px){
    font-size: 16px;
  }
`;


const MainWrap = styled.div`
  width:100%;
  height:fit-content ;
  text-align:center;
  position: absolute;
  top:calc(50% - 45px);
  left:50%;
  transform:translate(-50%,-50%);

  font-size: 50px;
  color: #ffffff;
  white-space:pre-wrap ;

  @media screen and (max-width: 750px){
    left:0 ;
    padding:0 30px ;
    transform:translateY(-50%);
  }
  @media screen and (max-width: 550px){
    font-size:36px;
    top:calc(50% - 40px);
  }

  p{
    font-family:Lato-Regular ;
    color:#fc983e;
  }

`;

const Logos=styled.div`
  position:absolute ;
  bottom: 125px;
  width: 100%;
  @media screen and (max-width: 750px){
    bottom:170px;
  }
  @media screen and (max-height: 896px){
    bottom:160px;
  }
  @media screen and (max-height: 844px){
    bottom:150px;
  }
  @media screen and (max-height: 780px){
    bottom:130px;
  }
  @media screen and (max-height: 680px){
    bottom:115px;
  }
  @media screen and (max-height: 568px){
    bottom:90px;
  }
 
 `

const FooterLogo=styled.div`
  position:absolute ;
  bottom: 40px;
  height:fit-content ;
  width: 100%;

  @media screen and (max-width: 750px){
    bottom:70px;
  }
  @media screen and (max-height: 896px){
    bottom:60px;
  }
  @media screen and (max-height: 844px){
    bottom:50px;
  }
  @media screen and (max-height: 780px){
    bottom:40px;
  }

  @media screen and (max-height: 568px){
    bottom:20px;
  }
 `
const text=`CoreDao  Name  Service`
const Homepage = () => {



  return (
    <Container>
      <HeaderWrap>
        <LogoWrap>
          <Logo src={opLogo}></Logo>
          {/* <LogoText>Star Name Service</LogoText> */}
        </LogoWrap>
        <AppButton>
          <AppText>Launch App</AppText>
        </AppButton>
      </HeaderWrap>
      <BgImg src={backgroundImg} />
      <MainWrap>
          <p>{text}</p>
      </MainWrap>
      <Logos>
          <LogoCard/>
      </Logos>
      <FooterLogo>
        <Footer/>
      </FooterLogo>
      
    </Container>
  );
};

export default Homepage;
