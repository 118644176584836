import Homepage from './Homepage'

function App() {

    return (
        <div className="App">
            <Homepage/>
        </div>
    );
}

export default App;
